<template>
    <div class="edu-frame" style="background-color: #cde7f7;">
        <edu-top-header></edu-top-header>
        <div class="edu-group" style="margin: 20px auto;">
            <div class="step-tips">您好，欢迎登录！</div>
            <div class="step-tips-detail">请填写身份证号及密码登录系统</div>
            <div class="class-form">
                <van-form @submit="login">
                    <van-field required v-model="formData.idCardNo" label="身份证号码" placeholder="请输入身份证号码" clearable :rules="[{ required: true, message: '请填写身份证号码' }]"/>
                    <van-field required v-model="formData.password" type="password" label="密码" placeholder="请输入密码" clearable :rules="[{ required: true, message: '请填写密码' }]"/>
                    <div class="form-tips">温馨提示：默认密码为<label style="color: #F57625">123456</label></div>
                    <van-field required v-model="formData.verifyCode" center clearable label="验证码" placeholder="请输入验证码" :rules="[{ required: true, message: '请填写验证码' }]">
                        <template #button>
                            <img @click="getVerifyCode" :src="verifyCode.base64">
                        </template>
                    </van-field>
                    <div class="button-group">
                        <van-button size="small" block type="info" native-type="button" @click="$router.go(-1);">上一步</van-button>
                        <van-button size="small" block type="info" native-type="submit">下一步</van-button>
                        <van-button size="small" block type="info" native-type="button" @click="editPwd">修改密码</van-button>
                    </div>
                </van-form>
            </div>
        </div>


<!--        <div class="edu-group" style="margin: 20px auto;">
        <div class="step-tips">注册：</div>
        <div class="guides">
          <div class="guides-btn" @click="$router.push({ name: 'registerforadult' })">
            <div class="guides-btn-logo-frame"><img class="guides-btn-logo" src="../../statics/images/adult.png"></div>
            <div class="guides-btn-label">幸福夜校学员注册</div>
          </div>
        </div>
      </div>-->
    </div>
</template>
<script>
    import EduTopHeader from "@/components/EduHeader";
    import {Form, Field, Button} from 'vant';
    import CustomerApi from "@/api/CustomerApi";
    import Tools from "@/api/Tools";
    export default {
        components: {
            EduTopHeader,
            VanForm: Form,
            VanField: Field,
            VanButton: Button
        },
        data() {
          return {
              formData: { idCardNo: '', password: '', verifyCode: '', loginType: Tools.getLoginType(), identityType: Tools.getIdentityType() },
              verifyCode: { code: null, base64: null }
          }
        },
        name: 'login',
        methods: {
            login() {
                if (this.formData.verifyCode != this.verifyCode.code) {
                    this.$toast('验证码不正确。')
                    return
                }
                // TODO 登录
                CustomerApi.loginByAdult(this.formData).then(response => {
                    if (response.code === 100) {
                        Tools.setCurCust(response.res)
                        // alert('token == ' + response.res.token)
                        Tools.setToken(response.res.token)
                        // TODO 判断用户是否有未填写完整新信息
                        /* CustomerApi.checkHasBlankField(response.res.custId).then(resp=>{
                            // console.log(resp.res)
                            if (resp.res == 0) {
                                this.$router.replace({ name: 'main' })
                            } else {
                                // TODO 跳到补完资料页
                                this.$router.replace({ name: 'zlxg', query: { hideBack: 1 } })
                            }
                        }) */
                      // 改用前端判断
                      console.log('lgs_CardType1 == ' + response.res.lgs_CardType1)
                      if (response.res.lgs_CardType1 == 8) {
                          this.$router.replace({ name: 'main' })
                      } else {
                        if (this.checkHasBlankField() == 1) {
                          this.$router.replace({ name: 'zlxg', query: { hideBack: 1 } })
                        } else {
                          this.$router.replace({ name: 'main' })
                        }
                      }


                    }
                })
            },
            checkHasBlankField() {
              var cust = Tools.getCurCust()
              if (cust.imgUrl == null || cust.imgUrl == '') {
                return 1
              }
              if ((cust.fullName == null || cust.fullName == '') ||
                  (cust.urgencyTelNo == null || cust.urgencyTelNo == '') ||
                  (cust.urgencyMan == null || cust.urgencyMan == '') ||
                  (cust.healthStatus == null || cust.healthStatus == '')) {
                return 1
              }
              if (cust.age >= 18 && (cust.oriDuty == null || cust.oriDuty == '')) {
                return 1
              }
              return 0
            },
            editPwd() {
                this.$router.push({ name: 'editpwd' })
            },
            getVerifyCode() {
                CustomerApi.getVerifyCode().then(response=>{
                    if (response.code === 100) {
                        this.verifyCode = response.res
                    }
                })
            }
        },
        mounted() {
            this.getVerifyCode()
        }
    }
</script>
<style scoped>
.step-tips-detail {
    font-size: 14px;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 15px;

}
    .form-tips{
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }

    .button-group {
        margin: 16px;
        display: flex;
    }
    .van-button {
        margin: 0 5px;
    }

.guides {
  width: 90%;
  padding: 0 2%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.guides-btn {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 7px 0px;
  border: 2px solid white;
  box-shadow: 2px 5px 4px lightgray;
  /*background-color: white;*/
  color: white;
  font-weight: bold;
  border-radius: 8px;
  background-image: linear-gradient(to right, deepskyblue , #1989fa);
}

.guides-btn-logo-frame {
  position: absolute;
  left: 10%;
  height: 100%;
  display: flex;
  align-items: center;
}

.guides-btn-logo {
  width: 30px;
  height: 30px;
}

.guides-btn-label {
  display: flex;
  justify-content: center;
}
.split-line {
  background-color: lightgray;
  height: 1px;
  width: 95%;
  border: 0px;
}
</style>
